/* RESPONSIBLE TEAM: team-actions */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import Validations from 'embercom/validations/custom-authentication/user-token-configuration';

export default class UserTokenConfiguration extends Fragment.extend(Validations) {
  @attr('string') declare tokenKey: string;
  @attr('number') declare ttlInSeconds: number | null;

  set ttlInSecondsValue(value: string | number | null) {
    if (!value) {
      this.set('ttlInSeconds', undefined);
    } else {
      this.set('ttlInSeconds', parseInt(value.toString(), 10));
    }
  }

  get ttlInSecondsValue(): number | null {
    return this.ttlInSeconds;
  }
}
